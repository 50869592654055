import Link from "next/link"
import Image from "components/Image"
import MiniCard from "components/DynamicComponent/atoms/MiniCard"
import Decoration from "public/assets/news-background.svg"
import { getStoryblokLink } from "utilities/getStoryblokLink"

export default function SupportSection({ blok }) {
  return (
    <section className="bg-springWood px-6 py-9 lg:py-12 lg:pb-16">
      <div className="relative mx-auto max-w-screen-xl">
        <Decoration
          aria-hidden
          className="absolute z-0 hidden max-h-[600px] w-[100rem] lg:-right-48 lg:top-2 lg:block lg:scale-105"
        />
        <div>
          {blok?.heading ? <h2 className="mx-auto text-center font-medium text-teal-dark">{blok?.heading}</h2> : null}
          {blok?.mini_cards?.length > 0 ? (
            <div className="grid place-items-stretch gap-7 py-6 sm:grid-cols-2 lg:grid-cols-3">
              {blok?.mini_cards?.map((card) => (
                <MiniCard key={card._uid} {...card} />
              ))}
            </div>
          ) : null}
        </div>
        <div>
          {blok?.sub_heading ? (
            <h3 className="mx-auto text-center font-medium text-teal-dark">{blok?.sub_heading}</h3>
          ) : null}
          {blok?.logo_cards?.length > 0 ? (
            <div className="mx-auto grid max-w-max gap-10 pt-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {blok?.logo_cards?.map((card) => {
                const href = getStoryblokLink(card?.link)

                return href ? (
                  <Link key={card?._uid} href={href} className="z-10" target="_blank">
                    <div className="flex h-[150px] w-[250px] items-center justify-center bg-white">
                      <Image
                        src={card?.logo?.filename}
                        alt={card?.logo?.alt ?? ""}
                        placeholder={card?.logo?.blurDataURL ? "blur" : "empty"}
                        blurDataURL={card?.logo?.blurDataURL}
                        height={150}
                        width={200}
                      />
                    </div>
                  </Link>
                ) : (
                  <div className="z-10 flex h-[150px] w-[250px] items-center justify-center bg-white">
                    <Image
                      src={card?.logo?.filename}
                      alt={card?.logo?.alt ?? ""}
                      className="max-h-[145px] object-contain"
                      placeholder={card?.logo?.blurDataURL ? "blur" : "empty"}
                      blurDataURL={card?.logo?.blurDataURL}
                      height={150}
                      width={200}
                    />
                  </div>
                )
              })}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  )
}
